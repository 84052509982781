
import './App.css';
import Main from './HomePage';

function App() {
  return (
      <Main />
  )
}

export default App;
